import Vue from "vue";

/*TITLE*/
document.title = "La Calera Residencial | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "La Calera Residencial";
Vue.prototype.$subtitle = "¿te quedas a vivir aquí?";

/*INTRO*/
Vue.prototype.$promoter = "La Calera Residencial";
Vue.prototype.$introSubtitle = "¿te quedas a vivir aquí?";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "salon-vela-smoked_web--20230113030136.jpg";
Vue.prototype.$image_kitchen = "";
Vue.prototype.$image_bath1 = "banyo-vela-smokedvela-smokedlimit-vela-smoked_web--20230113030159.jpg";
Vue.prototype.$image_bath2 = "aseo-vela-smokedvela-smoked_web--20230113030126.jpg";
Vue.prototype.$image_room = "";
Vue.prototype.$image_facade = "";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "salon-vela-smoked_web--20230113030136.jpg",
  },
  {
    src: "salon-vela-natural_web--20230113030143.jpg",
  },
  {
    src: "salon-vela-grey_web--20230113030150.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "banyo-vela-smokedvela-smokedlimit-vela-smoked_web--20230113030159.jpg",
  },
  {
    src: "banyo-vela-naturalvela-naturallimit-vela-natural_web--20230113030110.jpg",
  },
  {
    src: "banyo-vela-greyvela-greylimit-vela-grey_web--20230113030117.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "aseo-vela-smokedvela-smoked_web--20230113030126.jpg",
  },
  {
    src: "aseo-vela-naturalvela-natural_web--20230113030133.jpg",
  },
  {
    src: "aseo-vela-greyvela-grey_web--20230113030140.jpg",
  },
];

Vue.prototype.$gallery_images_room = [
];

Vue.prototype.$gallery_images_facade = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "6";
Vue.prototype.$gallery_grid_bath2 = "6";
Vue.prototype.$gallery_grid_room = "6";
Vue.prototype.$gallery_grid_facade = "6";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "";

/*3D FILM*/
Vue.prototype.$3dFilm = "";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=c%20francia%20c%20italia%20sevilla%2041749%20",
    text: "C/ Francia- C/ Italia, Sevilla (41749)",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=sevilla%2041749%20",
    text: "Sevilla (41749)",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:690963698/647840780",
    text: "690963698/647840780",
  },
  {
    icon: "mdi-email",
    link: "mailto:fgpromociones@buenavistagrupo.es",
    text: "fgpromociones@buenavistagrupo.es",
  },
];
